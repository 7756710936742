import React from 'react';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import { useCurrentNaraCampaigns } from '../../hooks/narapos';
// import { useAuthContext } from '../../contexts/AuthContext';

/**
 * 교차할인 캠페인 버튼
 * @constructor
 */
const CrossCampaignButton: React.FC<BoxProps> = (props: BoxProps) => {
  // const { state } = useAuthContext();

  // const isGodMode = React.useMemo<boolean>(() => {
  //   return state?.user?.god ?? false;
  // }, [state?.user?.god]);

  const naraCampaigns = useCurrentNaraCampaigns();

  // 대표 교차할인 캠페인
  const repCrossCamp = React.useMemo(() => {
    if ((naraCampaigns?.data?.campaigns?.cross?.length ?? 0) > 0) {
      return naraCampaigns?.data?.campaigns?.cross[0];
    }

    return null;
  }, [naraCampaigns?.data?.campaigns?.cross]);

  const campInfoStr = React.useMemo(() => {
    if (repCrossCamp === null) return '';
    // console.log('##@#@#@## repCrossCamp: ', repCrossCamp);
    const repRange = repCrossCamp.ranges[0];
    // console.log('#repRange.benf_aply_fg: ', repCrossCamp.benf_aply_fg, typeof repCrossCamp.benf_aply_fg);
    const discountStr =
      repCrossCamp.benf_aply_fg === '4'
        ? `${repRange.range_dc_val}%할인`
        : `${repRange.range_dc_val.toLocaleString()}원 할인`;
    return `지금 ${repRange.range_cond_val}병 이상 구매 시, ${discountStr}!!`;
  }, [repCrossCamp]);

  // god 사용자만 보이도록
  // if (!isGodMode) return null;

  // render - 진행 중인 교차할인 캠페인 없을 경우
  if (repCrossCamp === null) return null;

  // render
  return (
    <Box {...props}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          borderRadius: '8px',
          backgroundImage: `
          linear-gradient(315deg, #220348, #734ec3), url(https://www.transparenttextures.com/patterns/subtle-dots.png)
        `,
          backgroundSize: 'cover, auto', // 첫 번째 배경은 화면에 맞추고, 두 번째는 텍스처 반복
          backgroundBlendMode: 'overlay' // 텍스처와 그라데이션을 섞음
        }}
        onClick={(e) => {
          if (!e.isTrusted) return;
          window.open(`/campaign/wp/${repCrossCamp.camp_cd}`, '_blank');
        }}
      >
        <Box pt={0.5} pb={1.25}>
          <Typography variant="caption" fontSize={15}>
            ✨ 특가상품 보러가기 ✨
          </Typography>
          <Typography fontWeight={800} fontSize={20} lineHeight="24px" sx={{ display: 'block' }}>
            {campInfoStr}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default CrossCampaignButton;
