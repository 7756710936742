import React from 'react';
import { useRouter } from 'next/navigation';
import { useDebounce } from 'use-debounce';

import { createElasticAppSearchClient } from 'utils/ElasticSearch';
import { KakaoPixel } from 'utils/analytics/kakao-pixel';
import { GoogleAnalytics } from 'utils/analytics/google-analytics';

import { FlexBox } from 'components/flex-box';

import {
  Autocomplete,
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import WineIcon from 'assets/images/img_home_ctg_wine.png';
import WhiskeyIcon from 'assets/images/img_home_ctg_whiskey.png';
import NihonshouIcon from 'assets/images/img_home_ctg_nihonshu.png';
import OfferIcon from 'assets/images/img_home_ctg_plan.png';

interface TSearchResult {
  request_id: string;
  inputValue?: string;
  id: string;
  code: string;
  name: {
    ko: string;
    en: string;
  };
  category: string;
  type?: string;
}

const esPdataEngine = createElasticAppSearchClient('pdata');

const MainJumbotron: React.FC = () => {
  const router = useRouter();
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [searchText, setSearchText] = React.useState<string>('');
  const [deboucedSearchText] = useDebounce(searchText, 300);

  const [searching, setSearching] = React.useState<boolean>(false);
  const [searchResults, setSearchResults] = React.useState<TSearchResult[]>([]);

  // 상품 검색하기
  const searchProducts = async () => {
    const query: string = searchText.replace(/\s+/g, ' ').trim();

    // 입력된 검색어가 없을 경우
    if (query.length === 0) {
      setSearchResults([]);
      return;
    }

    const filters = {
      all: [{ show: 'true' }, { setup: 'true' }],
      none: [
        { category: ['offer', 'ticket'] }, // 검색에서 기획상품, 티켓 제외
        { vendor_lock: 'true' } // 프로모션이 종료된 특가상품(pdata)은 조회되지 않도록
      ]
    };

    const options = {
      query,
      filters,
      search_fields: {
        name_ko: { weight: 5 },
        name_en: { weight: 5 }
      },
      page: {
        size: 30,
        current: 1
      }
    };

    // 카카오 픽셀 추적코드
    KakaoPixel.search({ keyword: options.query, tag: 'pdata' });
    // 구글 애널리틱스
    GoogleAnalytics.logEvent('search', { search_term: options.query });

    setSearching(true);

    // Elastic Search로 상품검색 요청
    const response = await esPdataEngine.search('', options).catch((e) => {
      console.error('pdata 검색 실패', e);
      return { error: e };
    });

    setSearching(false);

    if (response.error) {
      console.error('pdata 검색 실패', response.error);
      setSearchResults([]);
      return;
    }

    const pdataList: any[] = [];
    // console.log('#response: ', response);

    // Elastic Search 결과를 상품리스트로 변환
    for (const result of response.results) {
      // Elastic Search 요청 아이디
      const request_id = response.info.meta.request_id;
      // pdata category: 'wine', 'whiskey', 'nihonshu'
      const category = result.getRaw('category');
      // pdata가 와인일 경우 type 세팅
      const type = category === 'wine' ? result.getRaw('type') : '';
      const item: TSearchResult = {
        request_id,
        id: result.getRaw('id'),
        code: result.getRaw('code'),
        name: {
          ko: result.getRaw('name_ko'),
          en: result.getRaw('name_en')
        },
        category
      };
      if (type) item.type = type;
      pdataList.push(item);
    }
    // console.log('#pdataList: ', pdataList);
    setSearchResults(pdataList);
  };

  React.useEffect(() => {
    searchProducts();
  }, [deboucedSearchText]);

  const moveToPdataDetail = ({ code, id, name, request_id, category, type }: TSearchResult) => {
    console.log('검색결과 클릭 -> pdata 상세 페이지로 이동:', process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
      // 구글 애널리틱스
      GoogleAnalytics.logEvent('select_item', {
        item_list_id: 'main_search_results',
        item_list_name: '메인화면 검색결과',
        items: [
          {
            item_id: id,
            item_name: name.ko,
            item_category: category
          }
        ]
      });

      try {
        if (request_id) {
          const esTrackData = {
            query: searchText,
            documentId: id,
            requestId: request_id,
            tags: ['winepedia', 'click', category]
          };
          if (type) esTrackData.tags.push(type);
          // console.log('#esTrackData: ', esTrackData);
          esPdataEngine.click(esTrackData);
        }
      } catch (e) {
        /* DO NOTHING */
      }
    }

    router.push(`/product/${code}`);
  };

  // render
  return (
    <Container maxWidth="lg" fixed>
      <Box py={2.5}>
        <Box
          component="form"
          width="100%"
          noValidate
          autoComplete="off"
          mt={2}
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
        >
          <Autocomplete
            fullWidth
            disablePortal
            freeSolo
            disableClearable
            id={`kmwine-pdata-search`}
            loading={searching}
            value={searchText}
            aria-label="상품검색"
            loadingText="상품을 찾는 중 이에요"
            options={searchResults}
            open={true}
            filterOptions={(options) => options}
            getOptionLabel={(option) => {
              if (typeof option === 'string') return option;
              if (option.inputValue) return option.inputValue;
              return option.name.ko;
            }}
            getOptionKey={(option) => {
              if (typeof option === 'string') return option;
              return option.code;
            }}
            onInputChange={(e, value, reason) => {
              if (reason === 'input') {
                setSearchText((value ?? '').trimStart());
              }
            }}
            onChange={(e, value, reason) => {
              if (reason === 'selectOption') {
                // pdata 상세 페이지로 이동하기
                if (typeof value === 'string') return;
                moveToPdataDetail(value);
              } else {
                console.log('### reason: ', reason);
                console.log('---> e: ', e);
                console.log('---> value: ', value);
              }
            }}
            inputValue={searchText}
            noOptionsText="검색 결과가 없습니다."
            renderInput={(params) => (
              <TextField {...params} placeholder="와인을 검색해보세요" size="medium" sx={{ bgcolor: 'white' }} />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <FlexBox flexDirection="column" justifyContent="center" height={45} width="100%">
                  <Typography noWrap fontSize="14px" lineHeight="19px">
                    {option.name.ko}
                  </Typography>
                  <Typography noWrap fontSize="12px" lineHeight="14px" color="#707078">
                    {option.name.en}
                  </Typography>
                </FlexBox>
              </li>
            )}
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget;
                if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight) {
                  // todo
                  console.debug('todo 다음 옵션리스트 fetch');
                }
              }
            }}
            sx={{ maxWidth: 'sm' }}
          />
        </Box>
        <Grid container spacing={isMdScreen ? 1 : 2.5} columns={12}>
          <Grid xs={3}>
            <CategoryButton
              onClick={() => {
                router.push('/search/wine');
              }}
              icon={WineIcon.src}
              label="와인"
            />
          </Grid>
          <Grid xs={3}>
            <CategoryButton
              onClick={() => {
                router.push('/search/whiskey');
              }}
              icon={WhiskeyIcon.src}
              label="위스키"
            />
          </Grid>
          <Grid xs={3}>
            <CategoryButton
              onClick={() => {
                router.push('/search/nihonshu');
              }}
              icon={NihonshouIcon.src}
              label="일본주"
            />
          </Grid>
          <Grid xs={3}>
            <CategoryButton
              onClick={() => {
                router.push('/search/offer');
              }}
              icon={OfferIcon.src}
              label="기획상품"
            />
          </Grid>
          {/*<Grid xs={5} md={2}>*/}
          {/*  <Button fullWidth variant="outlined" color="primary">*/}
          {/*    티켓*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </Container>
  );
};

const CategoryButton: React.FC<{ icon: string; label: string; onClick: () => void }> = ({ icon, label, onClick }) => {
  const theme: Theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Button
      fullWidth
      variant="outlined"
      color="dark"
      onClick={onClick}
      sx={{ bgcolor: 'background.paper', borderColor: 'transparent !important', p: isMdScreen ? 'inherit' : 2 }}
    >
      <Stack spacing={0.5} direction={isMdScreen ? 'column' : 'row'} sx={{ alignItems: 'center' }}>
        <Box component="img" src={icon} alt={label} sx={{ maxWidth: isMdScreen ? 50 : 36 }} />
        <Typography
          component="div"
          color="#3f3f3f"
          fontSize={isMdScreen ? 13 : 16}
          letterSpacing={isMdScreen ? '-0.3px' : '1px'}
          sx={{ minWidth: 80 }}
        >
          {label}
        </Typography>
      </Stack>
    </Button>
  );
};

export default MainJumbotron;
