import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

/**
 * 현재 진행 중인 나라포스 교차할인 캠페인 hook
 * @returns {any}
 */
export const useCurrentNaraCampaigns = (): any => {
  const [initialized, setInitialized] = React.useState<boolean>(false);

  // SWR fetcher
  const fetcher = async (url: string) => {
    const response = await axios.get(url);
    console.debug('진행 중인 캠페인 정보:', response.data);
    return response.data;
  };

  const { data, isLoading, error, isValidating } = useSWR('/api/pos/campaign/currents', fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: true,
    keepPreviousData: true,
    dedupingInterval: 1000 * 60 * 5 // 5분
  });

  React.useEffect(() => {
    if (!isLoading) setInitialized(true);
  }, [isLoading]);

  return {
    initialized,
    isLoading,
    isValidating,
    error, // 캠페인 조회 에러여부
    data
  };
};
