import React from 'react';
import { useRouter } from 'next/router';

import moment from 'moment';
import 'moment/locale/ko';

import { FlexBox, FlexRowCenter } from 'components/flex-box';
import VendorImage from 'components/vendor/VendorImage';
import Calculator from 'components/Calculator';
import PdataImage from 'components/pdata/PdataImage';

import { CardTitle } from 'pages-sections/home';

import { Box, ButtonBase, Card, CardContent, Divider, Pagination, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

type SelfPromotionCardProps = { selfPromotion: any };

/**
 * 홈 > 셀프 프로모션 카드
 */
const SelfPromotionCard: React.FC<SelfPromotionCardProps> = ({ selfPromotion: vp }: SelfPromotionCardProps) => {
  const router = useRouter();
  const nowMmt = moment();
  // 캠페인 기간 표현
  const vpStartMmt = moment(vp.start_dt);
  const vpEndMmt = moment(vp.end_dt);
  let format = 'MM. DD.';
  if (nowMmt.year() !== vpStartMmt.year() || nowMmt.year() !== vpEndMmt.year()) {
    format = 'YY. MM. DD.';
  }

  const { vendor, products } = vp;

  return (
    <Card>
      <CardContent>
        <Typography fontSize="12px" lineHeight="18px" color="#707078" letterSpacing="-0.3px" sx={{ mb: 0.5 }}>
          [와인샵 특가] {vpStartMmt.format(format)} ~ {vpEndMmt.format(format)}
        </Typography>
        <CardTitle size="small" primary={vp.name} />
        <Divider sx={{ mt: 1.75 }} />
        <Box mt={2}>
          {/* 캠페인 상점 정보 */}
          <FlexBox
            width="100%"
            component={ButtonBase}
            textAlign="left"
            onClick={() => {
              router.push(`/vendor/${vendor._id}`);
            }}
          >
            <VendorImage src={vendor.vendor_img?.[0]?.thumb} width={40} height={40} />
            <FlexBox flexDirection="column" justifyContent="space-between" width="calc(100% - 40px)" pl={1}>
              <Box width="100%">
                <Typography fontSize={14} fontWeight={700}>
                  {vendor.biz.name}
                </Typography>
                <Typography noWrap fontSize={10} sx={{ opacity: 0.7 }}>
                  {vendor.biz.address1}
                  {vendor.biz.address2 ? `, ${vendor.biz.address2}` : null}
                </Typography>
              </Box>
            </FlexBox>
          </FlexBox>
          <Box mt={2}>
            <SelfPromotionProductGrid promo_id={vp._id} vendor={vendor} products={products} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const SelfPromotionProductGrid = React.memo(
  ({ promo_id, vendor, products }: { promo_id: number; vendor: any; products: any[] }) => {
    const router = useRouter();

    const pageSize = 4;

    const initNumber = React.useMemo(() => {
      const value = window.sessionStorage.getItem(`vp-promo-${promo_id}-page`);
      const page = value !== null ? parseFloat(value) : 1;
      return page > Math.ceil(products.length / pageSize) ? 1 : page;
    }, []);

    const [currPage, setCurrPage] = React.useState<number>(initNumber);

    const handlePage = (page: number) => {
      setCurrPage(page);
      window.sessionStorage.setItem(`vp-promo-${promo_id}-page`, page.toString());
    };

    const [start, end] = React.useMemo(() => {
      const start = (currPage - 1) * pageSize;
      return [start, start + pageSize];
    }, [currPage]);

    React.useEffect(() => {
      // 페이지 새로고침할 때, currPage 초기화
      const handleRouteChange = () => {
        window.sessionStorage.removeItem(`vp-promo-${promo_id}-page`);
      };

      // 초기화용 이벤트 등록
      window.addEventListener('beforeunload', handleRouteChange);

      return () => {
        window.removeEventListener('beforeunload', handleRouteChange);
      };
    }, []);

    // render: SelfPromotionProductGrid
    return (
      <>
        <Grid container spacing={2}>
          {products.slice(start, end).map((product) => {
            let vintageStr = '';
            if (product.pdata.category === 'wine') {
              vintageStr = ` ${product.vintage === 'NV' ? 'NV' : product.vintage}`;
            }
            const productNm = `${product.name.ko}${vintageStr} ${Calculator.formatCapacity(product.capacity)}`;

            if (product.quantity === 0) {
              console.log('#품절: ', vendor.biz.name, product);
            }

            // render: self promotion product
            return (
              <Grid xs={6} key={`vpp-vendor-${vendor._id}-product-${product._id}`}>
                <ButtonBase
                  sx={{ width: '100%', mb: 1 }}
                  onClick={() => {
                    router.push(`/item/${product._id}/detail`);
                  }}
                  title={productNm}
                >
                  <Box textAlign="left" width="100%" sx={{ opacity: product.quantity === 0 ? 0.45 : 1 }}>
                    <PdataImage id={product.pdata._id} width="100%" height="140px" alt={product.name.ko} py={2} />
                    <Box mt={0.5}>
                      <Typography fontSize="12px" lineHeight="12px" color="#959595">
                        <del>{product.price.sale.toLocaleString()}원</del>
                      </Typography>
                      <Typography fontWeight={600}>{product.price.original.toLocaleString()}원</Typography>
                    </Box>
                    <Typography
                      fontSize="14px"
                      lineHeight="20px"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2
                      }}
                      letterSpacing="-0.3px"
                    >
                      {product.quantity === 0 ? '[품절] ' : ''}
                      {productNm}
                    </Typography>
                  </Box>
                </ButtonBase>
              </Grid>
            );
          })}
        </Grid>
        {Math.ceil(products.length / pageSize) > 1 && (
          <FlexRowCenter mt={1}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(products.length / pageSize)}
              page={currPage}
              onChange={(e, page) => {
                handlePage(page);
              }}
            />
          </FlexRowCenter>
        )}
      </>
    );
  }
);

export default SelfPromotionCard;
