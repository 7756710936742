import { useAppDispatch, useAppSelector } from 'store';
import tempCampaignOrderSlice, {
  reset,
  save,
  TcoCampaign,
  TcoOrderItem,
  TcoVendor,
  TempCampOrder,
  update
} from 'store/slices/temp-camp-order';

export interface TempCampaignOrderHook {
  saved: TempCampOrder;
  save: (args: { campaign: TcoCampaign; vendor: TcoVendor; items: TcoOrderItem[]; coupons?: any[] }) => void;
  update: (args: { items?: TcoOrderItem[]; coupons?: any[] }) => void;
  reset: () => void;
}

export const useTempCampaignOrder = (): TempCampaignOrderHook => {
  const dispatch = useAppDispatch();

  return {
    /** 저장된 캠페인 임시주문 */
    saved: useAppSelector((state): TempCampOrder => state[tempCampaignOrderSlice.name]),

    /** 캠페인 임시주문 저장 */
    save({ campaign, vendor, items, coupons }): void {
      dispatch(save({ campaign, vendor, items, coupons }));
    },

    /** 캠페인 임시주문 저장 */
    update({ items, coupons }): void {
      dispatch(update({ items, coupons }));
    },

    /**
     * Resets the state by dispatching the reset action.
     *
     * @return {void} Does not return a value.
     */
    reset(): void {
      dispatch(reset());
    }
  };
};
