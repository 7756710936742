import React from 'react';
import { useRouter } from 'next/navigation';

import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ko';

import { pdataTypeToString } from 'lib';
import { themeColors } from 'theme/themeColors';

import { FlexBetween, FlexBox, FlexRowCenter } from 'components/flex-box';
import VendorImage from 'components/vendor/VendorImage';
import Calculator from 'components/Calculator';
import PdataImage from 'components/pdata/PdataImage';

import { CardTitle } from 'pages-sections/home';

import {
  Box,
  ButtonBase,
  Card,
  Pagination,
  CardContent,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  TypographyProps
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';

type NaraCampaignCardProps = { campaign: any };

const PDATA_IMAGE_HEIGHT = 140;
const pageSize = 4;

const NaraCampaignCard: React.FC<NaraCampaignCardProps> = ({ campaign }: NaraCampaignCardProps) => {
  const router = useRouter();

  const nowMmt = moment();
  // 캠페인 기간 표현
  const campSttMmt = moment(campaign.camp_stt_date, 'YY-MM-DD HH:mm:ss');
  const campEndMmt = moment(campaign.camp_end_date, 'YY-MM-DD HH:mm:ss');
  let format = 'MM. DD.';
  if (nowMmt.year() !== campSttMmt.year() || nowMmt.year() !== campEndMmt.year()) {
    format = 'YY. MM. DD.';
  }

  const initVendorId = React.useMemo(() => {
    const value = window.sessionStorage.getItem(`main-wp-cpg-${campaign.camp_cd}-vid`);
    const vendorId = campaign.vendors.find((vendor) => vendor._id === value)?._id;
    return vendorId || campaign.vendors[0]._id;
  }, []);

  // 탭에서 선택된 입점샵 아이디
  const [vendorId, setVendorId] = React.useState<string>(initVendorId);

  const handleVendorChange = (vid: string) => {
    setVendorId(vid);
    setCurrPage(1); // page 1로 돌아가기
    window.sessionStorage.setItem(`main-wp-cpg-${campaign.camp_cd}-vid`, vid);
  };

  const vendor = React.useMemo(() => {
    return campaign.vendors[_.findIndex(campaign.vendors, { _id: vendorId })];
  }, [vendorId]);

  const tabsRef = React.useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = React.useState(false);

  const [totalPages, setTotalPages] = React.useState<number>(Math.ceil(vendor.products.length / pageSize));
  // 페이지
  const [currPage, setCurrPage] = React.useState<number>(1);

  const [start, end] = React.useMemo(() => {
    const start = (currPage - 1) * pageSize;
    return [start, start + pageSize];
  }, [currPage]);

  React.useEffect(() => {
    // 페이지 새로고침할 때, vendorAt 초기화
    const handleRouteChange = () => {
      window.sessionStorage.removeItem(`main-wp-cpg-${campaign.camp_cd}-vid`);
    };
    const checkScrollable = () => {
      if (tabsRef.current) {
        const scrollerEl = tabsRef.current.querySelector('.MuiTabs-scroller');
        const flexContainerEl = tabsRef.current.querySelector('.MuiTabs-flexContainer');

        setIsScrollable(flexContainerEl!.scrollWidth > scrollerEl!.clientWidth);
      }
    };

    // 초기화용 이벤트 등록
    window.addEventListener('beforeunload', handleRouteChange);
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('beforeunload', handleRouteChange);
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  // render
  return (
    <Card>
      <CardContent>
        <Typography fontSize="12px" lineHeight="18px" color="#707078" letterSpacing="-0.3px" sx={{ mb: 0.5 }}>
          [🏷️ 와인픽스 특가] {campSttMmt.format(format)} ~ {campEndMmt.format(format)}
        </Typography>
        <CardTitle size="small" primary={campaign.camp_nm} />

        <Box mt={1.5}>
          {campaign.vendors.length > 1 && (
            <Box mx={isScrollable ? '-16px' : 0}>
              <Tabs
                ref={tabsRef}
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={vendorId}
                aria-label="캠페인 진행 와인픽스 매장"
                onChange={(e, value) => {
                  handleVendorChange(value);
                }}
                sx={{ '& .MuiTabScrollButton-root.Mui-disabled': { opacity: 0.25 } }}
              >
                {campaign.vendors.map((campaignVendor) => {
                  return (
                    <Tab
                      key={`cpg-${campaign.camp_cd}-v-tab-${campaignVendor._id}`}
                      label={campaignVendor.biz.name}
                      value={campaignVendor._id}
                    />
                  );
                })}
              </Tabs>
            </Box>
          )}

          <Box mt={2}>
            {/* 캠페인 상점 정보 */}
            <FlexBox
              width="100%"
              component={ButtonBase}
              textAlign="left"
              onClick={() => {
                router.push(`/vendor/${vendor._id}`);
              }}
            >
              <VendorImage src={vendor.vendor_img?.[0]?.thumb} width={40} height={40} />
              <FlexBox flexDirection="column" justifyContent="space-between" width="calc(100% - 40px)" pl={1}>
                <Box width="100%">
                  <Typography fontSize={14} fontWeight={700}>
                    {vendor.biz.name}
                  </Typography>
                  <Typography noWrap fontSize={10} sx={{ opacity: 0.7 }}>
                    {vendor.biz.address1}
                    {vendor.biz.address2 ? `, ${vendor.biz.address2}` : null}
                  </Typography>
                </Box>
              </FlexBox>
            </FlexBox>
            <Box mt={2}>
              <Grid container spacing={2}>
                {vendor.products.slice(start, end).map((product) => {
                  return vendor.products.length > 1 ? (
                    <CampaignProductGrid
                      key={`cpg-${campaign.camp_cd}-vendor-${vendor._id}-product-${product._id}`}
                      product={product}
                      onClick={() => {
                        router.push(`/item/${product._id}/detail`);
                      }}
                    />
                  ) : (
                    <CampaignProductSingleGrid
                      key={`cpg-${campaign.camp_cd}-vendor-${vendor._id}-product-${product._id}`}
                      product={product}
                      onClick={() => {
                        router.push(`/item/${product._id}/detail`);
                      }}
                    />
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
        {totalPages > 1 && (
          <FlexRowCenter mt={1}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(vendor.products.length / pageSize)}
              page={currPage}
              onChange={(e, page) => {
                setCurrPage(page);
              }}
            />
          </FlexRowCenter>
        )}
      </CardContent>
    </Card>
  );
};

export default NaraCampaignCard;

const CampaignProductGrid = React.memo(({ product, onClick }: { product: any; onClick: any }) => {
  let vintageStr = '';
  if (product.pdata.category === 'wine') {
    vintageStr = ` ${product.vintage === 'NV' ? 'NV' : product.vintage}`;
  }
  const productNm = `${product.name.ko}${vintageStr} ${Calculator.formatCapacity(product.capacity)}`;
  return (
    <Grid xs={6}>
      <ButtonBase sx={{ width: '100%', mb: 1 }} onClick={onClick} title={productNm}>
        <Box textAlign="left" width="100%">
          <PdataImage id={product.pdata._id} width="100%" height={PDATA_IMAGE_HEIGHT} alt={product.name.ko} py={2} />
          <Box mt={0.5}>
            <Typography fontSize="12px" lineHeight="12px" color="#959595">
              <del>{product.price.sale.toLocaleString()}원</del>
            </Typography>
            <Typography fontWeight={600}>{product.price.original.toLocaleString()}원</Typography>
          </Box>
          <ProductNameKo>{productNm}</ProductNameKo>
        </Box>
      </ButtonBase>
    </Grid>
  );
});

const ProductNameKo = styled((props: TypographyProps) => (
  <Typography component="span" fontSize="14px" lineHeight="18px" {...props} />
))({
  letterSpacing: '-0.3px',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2
});

const CampaignProductSingleGrid = React.memo(({ product, onClick }: { product: any; onClick: any }) => {
  let vintageStr = '';
  if (product.pdata.category === 'wine') {
    vintageStr = ` ${product.vintage === 'NV' ? 'NV' : product.vintage}`;
  }
  const productNm = `${product.name.ko}${vintageStr} ${Calculator.formatCapacity(product.capacity)}`;

  const { pdata } = product;
  const pdataTypeStr = pdata.category !== 'offer' ? pdataTypeToString(pdata.category, pdata.type) : null;
  const varietyStr = pdata.category === 'wine' ? pdata.variety.ko ?? null : null;

  // render : single
  return (
    <Grid xs={12}>
      <ButtonBase sx={{ width: '100%', mb: 1 }} onClick={onClick} title={productNm}>
        <FlexBox textAlign="left" width="100%">
          <PdataImage id={product.pdata._id} width="86px" height={118} alt={product.name.ko} py={1.5} />
          <FlexBetween flexDirection="column" alignItems="flex-start" pl={1.5} py={0.5} width="calc(100% - 86px)">
            <Box width="100%">
              <Box>
                <Typography component="span" fontWeight={600}>
                  {product.price.original.toLocaleString()}원
                </Typography>
                <Typography
                  component="span"
                  fontSize="12px"
                  lineHeight="12px"
                  color="#959595"
                  sx={{ display: 'inline-block', ml: 1 }}
                >
                  <del>{product.price.sale.toLocaleString()}원</del>
                </Typography>
              </Box>
              <Box mt={0.5}>
                <ProductNameKo>{product.name.ko}</ProductNameKo>
                <ProductNameKo sx={{ mt: 0.2 }} color="#707078" fontSize="12px" lineHeight="14px">
                  {product.name.en}
                </ProductNameKo>
              </Box>
              <Box>
                {/* Stack: vintage & capacity */}
                <Stack
                  direction="row"
                  alignItems="center"
                  mt={0.5}
                  spacing={0.5}
                  color="#707078"
                  divider={<Divider orientation="vertical" flexItem sx={{ my: '5px !important' }} />}
                >
                  {vintageStr ? <Typography>{vintageStr}</Typography> : null}
                  <Typography>{Calculator.formatCapacity(product.capacity)}</Typography>
                </Stack>
              </Box>
            </Box>
            <Box mt={0.5} width="100%">
              <Stack direction="row" spacing={0.75} useFlexGap flexWrap="wrap">
                {pdata.category !== 'offer' ? <ProductTag str={product.pdata.country.ko} /> : null}
                {pdataTypeStr ? <ProductTag str={pdataTypeStr} /> : null}
                {varietyStr ? <ProductTag str={varietyStr} /> : null}
              </Stack>
            </Box>
          </FlexBetween>
        </FlexBox>
      </ButtonBase>
    </Grid>
  );
});
const ProductTag = React.memo(({ str }: { str: string }) => {
  return (
    <Box component="span" fontSize="10px" lineHeight="14px" borderRadius="4px" color={themeColors.primary[400]}>
      <span style={{ display: 'inline-block', marginRight: '1px' }}>#</span>
      {str}
    </Box>
  );
});
