import React from 'react';
import { useRouter } from 'next/router';

import { PdataCategory } from 'models/pdata/Pdata.types';
import PdataImage from 'components/pdata/PdataImage';
import { FlexBox, FlexRowCenter } from 'components/flex-box';

import { CardTitle } from 'pages-sections/home';

import {
  Card,
  CardContent,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  Typography,
  // useMediaQuery,
  useTheme
} from '@mui/material';

type SalesChartCardProps = {
  salesChart: {
    sales_record: number;
    _id: string;
    code: string;
    category: PdataCategory;
    name: { ko: string; en: string };
    price_min: number | null;
  }[];
};

const pageSize = 5;

const SalesChartCard: React.FC<SalesChartCardProps> = ({ salesChart }: SalesChartCardProps) => {
  const router = useRouter();
  const theme = useTheme();
  // 화면사이즈가 sm보다 작을 때
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const initNumber = React.useMemo(() => {
    const value = window.sessionStorage.getItem('main-sales-chart-page');
    const page = value !== null ? parseFloat(value) : 1;
    return page > Math.ceil(salesChart.length / pageSize) ? 1 : page;
  }, []);

  const [currPage, setCurrPage] = React.useState<number>(initNumber);

  const handlePage = (page: number) => {
    setCurrPage(page);
    window.sessionStorage.setItem('main-sales-chart-page', page.toString());
  };

  const [start, end] = React.useMemo(() => {
    const start = (currPage - 1) * pageSize;
    return [start, start + pageSize];
  }, [currPage]);

  // render
  return (
    <Card>
      <CardContent sx={{ pb: '20px !important' }}>
        <CardTitle primary="주간 판매순위️" />
        <List sx={{ pb: 0 }}>
          {salesChart.slice(start, end).map((rankPdata, i) => {
            return (
              <ListItemButton
                disableGutters
                key={`main-rank-${rankPdata._id}-${i}`}
                onClick={(e) => {
                  if (!e.isTrusted) return false;
                  router.push(`/product/${rankPdata.code}`);
                  // /product/00001484F4INASUH21T4
                }}
              >
                <ListItemAvatar>
                  <PdataImage id={rankPdata._id} width="50px" height="70px" alt={rankPdata.name.ko} py={1}>
                    <FlexBox
                      bgcolor="white"
                      width={16}
                      height={16}
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        borderTopLeftRadius: '8px',
                        borderBottomRightRadius: '8px'
                      }}
                    >
                      <Typography fontSize={10} lineHeight="16px">
                        {pageSize * (currPage - 1) + i + 1}
                      </Typography>
                    </FlexBox>
                  </PdataImage>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      fontSize={14}
                      fontWeight={600}
                      lineHeight="20px"
                      letterSpacing="-0.3px"
                    >
                      {/* {i === 0 ? `🎖 ` : null} */}
                      {rankPdata.name.ko}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        color="#707078"
                        fontSize={12}
                        lineHeight="14px"
                        letterSpacing="-0.5px"
                        sx={{ display: 'block' }}
                      >
                        {rankPdata.name.en}
                      </Typography>
                      {rankPdata.price_min !== null ? (
                        <Typography
                          component="span"
                          color="#1e1e23"
                          fontSize="14px"
                          lineHeight="15px"
                          letterSpacing="-0.3px"
                          sx={{ mt: 1, display: 'block' }}
                        >
                          {rankPdata.price_min.toLocaleString()}원 ~
                        </Typography>
                      ) : (
                        <Typography
                          component="span"
                          color="lightGray"
                          fontSize="12px"
                          lineHeight="15px"
                          letterSpacing="-0.3px"
                          sx={{ mt: 1, display: 'block' }}
                        >
                          [품절]
                        </Typography>
                      )}
                    </>
                  }
                  sx={{ my: 0 }}
                />
              </ListItemButton>
            );
          })}
        </List>
        {Math.ceil(salesChart.length / pageSize) > 1 && (
          <FlexRowCenter mt={1}>
            <Pagination
              hideNextButton
              hidePrevButton
              variant="outlined"
              shape="rounded"
              count={Math.ceil(salesChart.length / pageSize)}
              page={currPage}
              onChange={(e, page) => {
                handlePage(page);
              }}
            />
          </FlexRowCenter>
        )}
      </CardContent>
    </Card>
  );
};

export default SalesChartCard;
