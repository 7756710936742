import React, { createContext, FC, ReactNode, useContext } from 'react';
import UaParser from 'ua-parser-js';
import { deviceType, isAndroid, isDesktop, isIOS, isIOS13, isIPhone13, isMobile, isTablet } from 'react-device-detect';

import { AppState, useAppDispatch, useAppSelector } from 'store';
import { DeviceState, initialState as initialDeviceState, setDevice } from 'store/slices/device';
import { setGeolocationState, setPosition } from 'store/slices/geolocation';
import { useBrowserLayoutEffect } from 'hooks/useBrowserLayoutEffect';

// =================================================================================
// type InitialState = { initialized: boolean } & any;
// type InitialState = { cart: any; device: { initialized: boolean; isAndroid?: boolean } } & any;

// =================================================================================

interface ContextProps {
  // state: InitialState;
  cart: any; // todo delete
  device: DeviceState;
}

const AppContext = createContext<ContextProps>({
  cart: {},
  device: initialDeviceState
});

// =======================================================
type AppProviderProps = { children: ReactNode };
// =======================================================

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const contextValue = useAppSelector(
    (state: AppState) =>
      ({
        cart: state.cart,
        device: state.device
      } as AppState)
  );

  useBrowserLayoutEffect(() => {
    const result = UaParser(window.navigator.userAgent);

    const browserInfo = {
      ...result,
      deviceType,
      isIOS: isIOS || isIOS13 || isIPhone13,
      isAndroid,
      isMobile,
      isTablet,
      isDesktop
    };

    dispatch(setDevice({ initialized: true, ...browserInfo }));

    // console.log('################################### 사용자 위치정보 조회');
    try {
      window.navigator.permissions
        .query({ name: 'geolocation' })
        .then(({ state: permissionState }: { state: PermissionState }) => {
          console.debug('[geolocation] permissionState', permissionState);
          if (permissionState === 'granted') {
            // 사용자 위치정보 조회 시작
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                dispatch(setPosition({ latitude, longitude }));
              },
              (error: GeolocationPositionError) => {
                const { code, PERMISSION_DENIED } = error;
                if (code === PERMISSION_DENIED) {
                  dispatch(setGeolocationState({ state: 'denied' }));
                } else {
                  console.error('-#(2039480293840239840938--> error: ', error);
                }
              },
              {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0
              }
            );
          } else if (permissionState === 'prompt') {
            dispatch(setGeolocationState({ state: permissionState }));
          } else if (permissionState === 'denied') {
            dispatch(setGeolocationState({ state: permissionState }));
          }
        })
        .catch((error) => {
          console.error('위치정보에 접근할 수 없는 브라우저입니다#1', error);
          dispatch(setGeolocationState({ state: 'unavailable' }));
        });
    } catch (e) {
      console.error('위치정보에 접근할 수 없는 브라우저입니다#2', e);
      dispatch(setGeolocationState({ state: 'unavailable' }));
    }
  }, []);

  // const contextValue = useMemo(() => ({ state }), [state]);
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext<ContextProps>(AppContext);

export default AppContext;
