import React from 'react';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  Pagination,
  Stack,
  Typography,
  TypographyProps
} from '@mui/material';
import { CardTitle } from 'pages-sections/home';
import moment from 'moment/moment';
import Grid from '@mui/material/Unstable_Grid2';
import Calculator from '../../components/Calculator';
import PdataImage from '../../components/pdata/PdataImage';
import { pdataTypeToString } from '../../lib';
import { FlexBetween, FlexBox, FlexRowCenter } from '../../components/flex-box';
import { styled } from '@mui/material/styles';
import { themeColors } from '../../theme/themeColors';
import { useRouter } from 'next/router';

type NaraCrossCampaignCardProps = {
  loading: boolean;
  campaign: any; // todo type
};

const PDATA_IMAGE_HEIGHT = 140;
const pageSize = 4;

/**
 * 나라포스 교차할인 캠페인 카드
 */
const NaraCrossCampaignCard: React.FC<NaraCrossCampaignCardProps> = ({ loading, campaign }) => {
  const router = useRouter();

  const nowMmt = React.useMemo(() => {
    return moment();
  }, []);

  // 캠페인 기간 표현
  const campSttMmt = moment(campaign.camp_stt_date, 'YYYYMMDD');
  const campEndMmt = moment(campaign.camp_end_date, 'YYYYMMDD');
  let format = 'MM. DD.';
  if (nowMmt.year() !== campSttMmt.year() || nowMmt.year() !== campEndMmt.year()) {
    format = 'YY. MM. DD.';
  }

  const totalPages = Math.ceil(campaign.items.length / pageSize);
  // 페이지
  const [currPage, setCurrPage] = React.useState<number>(1);

  const [start, end] = React.useMemo(() => {
    const start = (currPage - 1) * pageSize;
    return [start, start + pageSize];
  }, [currPage]);

  // render
  return (
    <Card sx={{ position: 'relative' }}>
      {loading && (
        <Box position="absolute" top={0} left={0} width="100%" aria-label="정보 갱신 중">
          <LinearProgress color="primary" />
        </Box>
      )}

      <CardContent>
        <Typography fontSize="12px" lineHeight="18px" color="#707078" letterSpacing="-0.3px" sx={{ mb: 0.5 }}>
          [🛍 와인픽스 교차할인️] {campSttMmt.format(format)} ~ {campEndMmt.format(format)}
        </Typography>
        <CardTitle size="small" primary={campaign.camp_nm} />

        <Box
          component={ButtonBase}
          mt={2.5}
          onClick={() => {
            router.push(`/campaign/wp/${campaign.camp_cd}`);
          }}
        >
          <Grid container spacing={2}>
            {campaign.items.slice(start, end).map((product, i) => {
              // console.log('---> product: ', product);
              return campaign.items.length > 4 ? (
                <CampaignProductGrid
                  key={`cross-cpg-${campaign.camp_cd}-product-${product._id}-${i}`}
                  product={product}
                />
              ) : (
                <CampaignProductSingleGrid
                  key={`cross-cpg-${campaign.camp_cd}-product-${product._id}-${i}`}
                  product={product}
                />
              );
            })}
          </Grid>
        </Box>
        {totalPages > 1 && (
          <FlexRowCenter mt={2.5}>
            <Pagination
              variant="outlined"
              shape="rounded"
              count={Math.ceil(campaign.items.length / pageSize)}
              page={currPage}
              onChange={(e, page) => {
                setCurrPage(page);
              }}
            />
          </FlexRowCenter>
        )}
      </CardContent>
    </Card>
  );
};

export default NaraCrossCampaignCard;

const CampaignProductGrid = React.memo(({ product }: { product: any }) => {
  let vintageStr = '';
  if (product.category === 'wine') {
    vintageStr = ` ${product.vintage === 'NV' ? 'NV' : product.vintage}`;
  }
  const productNm = `${product.name.ko}${vintageStr} ${Calculator.formatCapacity(product.capacity)}`;

  // invalid product - ERP 매칭이 안되는 등의 사유
  const noPrice = (product.min_price_original ?? 0) === 0;

  // render
  return (
    <Grid xs={6}>
      <Box textAlign="left" width="100%" title={productNm}>
        <PdataImage id={product.pdata_id} width="100%" height={PDATA_IMAGE_HEIGHT} alt={product.name.ko} py={2} />
        <Box mt={0.75}>
          {!noPrice ? (
            <Typography fontWeight={600}>{product.min_price_original.toLocaleString()}원&nbsp;~</Typography>
          ) : (
            <Typography color="#959595">[품절]</Typography>
          )}
        </Box>
        <ProductNameKo>{productNm}</ProductNameKo>
      </Box>
    </Grid>
  );
});

const CampaignProductSingleGrid = React.memo(({ product }: { product: any }) => {
  let vintageStr = '';
  if (product.category === 'wine') {
    vintageStr = ` ${product.vintage === 'NV' ? 'NV' : product.vintage}`;
  }
  const productNm = `${product.name.ko}${vintageStr} ${Calculator.formatCapacity(product.capacity)}`;

  const pdataTypeStr = product.category !== 'offer' ? pdataTypeToString(product.category, product.type) : null;
  const varietyStr = product.category === 'wine' ? product.variety.ko ?? null : null;

  // invalid product - ERP 매칭이 안되는 등의 사유
  const noPrice = (product.min_price_original ?? 0) === 0;

  // render : single
  return (
    <Grid xs={12}>
      <FlexBox textAlign="left" width="100%" title={productNm}>
        <PdataImage id={product.pdata_id} width="86px" height={118} alt={product.name.ko} py={1.5} />
        <FlexBetween flexDirection="column" alignItems="flex-start" pl={1.5} py={0.5} width="calc(100% - 86px)">
          <Box width="100%">
            <Box>
              {!noPrice ? (
                <Typography component="span" fontWeight={600}>
                  {product.min_price_original.toLocaleString()}원&nbsp;~
                </Typography>
              ) : (
                <Typography component="span" color="#959595">
                  [품절]
                </Typography>
              )}
            </Box>
            <Box mt={0.5}>
              <ProductNameKo>{product.name.ko}</ProductNameKo>
              <ProductNameKo sx={{ mt: 0.2 }} color="#707078" fontSize="12px" lineHeight="14px">
                {product.name.en}
              </ProductNameKo>
            </Box>
            <Box>
              {/* Stack: vintage & capacity */}
              <Stack
                direction="row"
                alignItems="center"
                mt={0.5}
                spacing={0.5}
                color="#707078"
                divider={<Divider orientation="vertical" flexItem sx={{ my: '5px !important' }} />}
              >
                {vintageStr ? <Typography>{vintageStr}</Typography> : null}
                <Typography>{Calculator.formatCapacity(product.capacity)}</Typography>
              </Stack>
            </Box>
          </Box>
          <Box mt={0.5} width="100%">
            <Stack direction="row" spacing={0.75} useFlexGap flexWrap="wrap">
              {product.category !== 'offer' ? <ProductTag str={product.country.ko} /> : null}
              {pdataTypeStr ? <ProductTag str={pdataTypeStr} /> : null}
              {varietyStr ? <ProductTag str={varietyStr} /> : null}
            </Stack>
          </Box>
        </FlexBetween>
      </FlexBox>
    </Grid>
  );
});

const ProductNameKo = styled((props: TypographyProps) => (
  <Typography component="span" fontSize="14px" lineHeight="18px" {...props} />
))({
  letterSpacing: '-0.3px',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2
});

const ProductTag = React.memo(({ str }: { str: string }) => {
  return (
    <Box component="span" fontSize="10px" lineHeight="14px" borderRadius="4px" color={themeColors.primary[400]}>
      <span style={{ display: 'inline-block', marginRight: '1px' }}>#</span>
      {str}
    </Box>
  );
});
