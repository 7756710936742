import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BenfAplyFg, CampaignType, DupAplyFg } from 'models/campaign/Campaign.model';
import { TVendorImage } from 'models/Vendor.types';

export type TempCampOrder = {
  /** 임시저장 캠페인 주문의 캠페인 정보 */
  campaign: TcoCampaign | null;

  /**
   * 캠페인 매장
   * - 캠페인 매장코드(MS_NO)로 연결된 1KMWINE의 vendor 정보
   */
  vendor: TcoVendor | null;

  /**
   * 주문상품 정보
   */
  items: TcoOrderItem[];

  /**
   * 결제시 적용할 쿠폰 정보
   */
  coupons?: any[]; // todo type
  // error?: Error | null;
};

/**
 * 캠페인 주문 임시저장 초기 상태
 * @type {{campaign: null, vendor: null, items: []}}
 */
const initialState: TempCampOrder = {
  campaign: null,
  vendor: null,
  items: []
};

/**
 * 임시저장된 캠페인 주문 초기화 액션
 */
const reset = createAction('tempCampaignOrder/reset');

/**
 * A slice for managing temporary campaign orders.
 *
 * This slice handles the state and actions related to temporary campaign orders, including saving, updating,
 * and resetting temporary campaign order data.
 *
 * Variable: tempCampaignOrderSlice
 *
 * Reducers:
 * - save: Temporarily saves a campaign order, allowing only cross-discount campaigns. Any other types of campaigns will trigger an error.
 * - update: Updates a temporarily saved campaign order. The `campaign` and `vendor` fields are not processed here as they are handled by the `save` reducer.
 *
 * ExtraReducers:
 * - reset: Resets the state of the temporary campaign order to its initial state.
 */
const tempCampaignOrderSlice = createSlice({
  name: 'tempCampaignOrder',
  initialState,
  reducers: {
    /**
     * 캠페인 주문 임시저장
     *
     * 교차할인 외에는 오류를 발생하도록 함
     * 추후 다른 캠페인 적용작업 개발시 수정 필요
     */
    save(
      state: TempCampOrder,
      action: PayloadAction<{ campaign: TcoCampaign; vendor: TcoVendor; items?: TcoOrderItem[]; coupons?: any[] }>
    ) {
      console.log('[reducer][temp-campaign-order] 캠페인 주문 임시저장', action);
      const { payload } = action;

      // todo 다른 유형의 캠페인도 처리할 수 있도록 수정
      if (payload?.campaign?.type !== CampaignType.CROSS_DISCOUNT) {
        console.error('[temp-campaign-order][save] 교차할인 캠페인만 임시저장 가능합니다.');
        throw new Error('교차할인 캠페인만 임시저장 가능합니다.');
      }

      /* 유효성 검사 시작 [S] */

      const { campaign } = payload;
      validateCampaign(campaign); // 캠페인 유효성 검사
      // console.log('캠페인 정보 이상 무!');

      const { vendor } = payload;
      validateVendor(vendor); // 입점샵 유효성 검사
      // console.log('입점샵 정보 이상 무!');

      const { items = [] } = payload;
      validateItems(items); // 주문상품 유효성 검사
      // console.log('주문상품 정보 이상 무!');

      const { coupons = [] } = payload;
      validateCoupons(coupons); // 쿠폰 유효성 검사
      // console.log('쿠폰 정보 이상 무!');

      /* 유효성 검사 시작 [E] */
      state.campaign = campaign;
      state.vendor = vendor;
      state.items = items;
      state.coupons = coupons;

      // console.log('state: ', state);

      // if (items.length > 0) {
      //   state.items = items;
      // } else {
      //   /*
      //    * Immer 상태에서는 속성을 삭제하려면 Immer에서 생성한 상태 객체를 명시적으로 복사해야 합니다.
      //    * 하지만 Redux Toolkit의 사용 패턴에서는 이 방법을 권장하지 않습니다.
      //    */
      //   // delete state.items;
      //   state.items = [];
      // }
    },

    /** 임시저장된 캠페인 주문 업데이트 */
    update(state: TempCampOrder, { payload }) {
      /*
       * todo 기존 state에 campaign이나 vendor가 없을경우 에러처리
       *  - 에러 메세지: '임시저장된 캠페인 주문이 없습니다.'
       */

      // campaign, vendor은 'save'에서만 처리하도록 함
      console.log('[reducer][temp-campaign-order] 임시저장된 캠페인 주문 업데이트', payload);

      // todo 주문상품 유효성 검사

      // todo 쿠폰 유효성 검사
    }
  },
  extraReducers: (builder) => {
    /** 임시저장 주문 초기화 */
    builder.addCase(reset, () => initialState);
  }
});

export const { save, update } = tempCampaignOrderSlice.actions;
export { reset };
export default tempCampaignOrderSlice;

/**
 * TcoCampaign represents a campaign with various attributes such as code, type,
 * name, duration, and application rules.
 */
export type TcoCampaign = {
  /** 캠페인 코드 */
  camp_cd: string;

  /**
   * 캠페인 유형
   *
   * 1KMWINE에서 캠페인 구분을 쉽게하기 위해 임의로 지정한 값
   */
  type: CampaignType;

  /**
   * 캠페인명칭
   */
  camp_nm: string;

  /** 시작일자 (YYYYMMDD) */
  camp_stt_date: string;

  /** 종료일자 (YYYYMMDD) */
  camp_end_date: string;

  // cust_gbn_fg
  // conf_dc_fg
  // cond_good_aply_fg
  // cond_value_fg
  // cpn_cd

  /** 혜택적용기준 */
  benf_aply_fg: BenfAplyFg;

  /** 중복적용기준 */
  dup_aply_fg: DupAplyFg;

  /** 적용순위번호 */
  aply_rank_no: number;

  /**
   * 구간설정정보
   *
   * 교차할인, 세트할인일 경우에만 존재
   */
  ranges?: any[]; // todo type
};

/**
 * Represents a TCO Vendor.
 *
 * @typedef {Object} TcoVendor
 * @property {string} _id - Vendor ID.
 * @property {string} nara_pos_vendor_id - 나라포스 매장코드.
 * @property {Object} biz - 매장정보.
 * @property {string} biz.name - 매장명.
 * @property {string} biz.address1 - 매장주소.
 * @property {string} biz.address2 - 상세주소.
 * @property {TVendorImage[]} [vendor_img] - 매장 대표이미지.
 */
export type TcoVendor = {
  /** vendor ID */
  _id: string;

  /** 나라포스 매장코드 */
  nara_pos_vendor_id: string;

  /** 매장정보 */
  biz: {
    /** 매장명 */
    name: string;

    /** 매장주소 */
    address1: string;

    /** 상세주소 */
    address2: string;
  };

  /** 매장 대표이미지 */
  vendor_img?: TVendorImage[];
};

export type TcoOrderItem = {
  /** 상품정보 */
  product: {
    /** product ID */
    _id: string;
    name: { ko: string; en: string };
    vintage: string | 'NV';
    capacity: string; // todo number로 변경할 것
    pdata: {
      _id: string;
      [key: string]: any; // todo 상세하게 정의
    };
    price: {
      original: number; // 할인적용금액
      sale: number; // 원가
    };
  };

  /** 주문수량 */
  quantity: number;

  /** 요청사항 */
  comment?: string;
};

/**
 * Validates the provided campaign object to ensure it contains necessary information.
 *
 * @param {object} campaign - The campaign object to be validated.
 * @param {string} campaign.camp_cd - The campaign code which must be present.
 * @throws Will throw an error if the campaign object or the campaign code is missing.
 * @return {void}
 */
function validateCampaign(campaign: any): void {
  if (!campaign) {
    console.error('[temp-campaign-order][save] 캠페인 정보가 없습니다.');
    throw new Error('캠페인 정보가 없습니다.');
  }

  // campaign code is required
  if (!campaign.camp_cd) {
    console.error('[temp-campaign-order][save] 캠페인 코드가 없습니다.');
    throw new Error('캠페인 코드가 없습니다.');
  }
}

/**
 * Validates the vendor object ensuring it contains necessary properties.
 *
 * @param {object} vendor - The vendor object to be validated.
 * @param {string} vendor._id - The unique identifier for the vendor.
 * @param {string} vendor.nara_pos_vendor_id - The Nara POS vendor code.
 * @throws Will throw an error if the vendor object is missing or has invalid properties.
 * @return {void}
 */
function validateVendor(vendor: any): void {
  if (!vendor) {
    console.error('[temp-campaign-order][save] 매장 정보가 없습니다.');
    throw new Error('매장 정보가 없습니다.');
  }

  // vendor ID is required
  if (!vendor._id) {
    console.error('[temp-campaign-order][save] vendor ID가 없습니다.');
    throw new Error('vendor ID가 없습니다.');
  }

  // Nara POS vendor code is required
  if (!vendor.nara_pos_vendor_id) {
    console.error('[temp-campaign-order][save] 나라포스 매장코드가 없습니다.');
    throw new Error('나라포스 매장코드가 없습니다.');
  }
}

function validateItems(items: TcoOrderItem[]): void {
  let errorCount = 0; // 문제있는 상품 데이터 개수

  // 선택상품을 하나씩 돌면서 검사
  for (const item of items) {
    // 상품정보가 없거나 상품 개수가 1개 미만일 경우
    if (!item.product || (item.quantity ?? 0) < 1) {
      console.error('잘못된 상품정보: ', item);
      errorCount++;
      continue;
    }

    // const { product } = item;
    // 상품 _id가 없을 경우
    // console.log('# product: ', product);
  }

  if (errorCount > 0) throw new Error('주문상품 정보가 올바르지 않습니다.[count=' + errorCount + ']');
}

function validateCoupons(coupons: any[]): void {
  // todo 적용할 쿠폰 유효성 검사
}
