import { TVendorImage } from '../Vendor.types';

/**
 * 캠페인 타입
 *
 * 나라포스 캠페인을 분류하기 위해 와인원에서 임의로 분류한 타입
 */
export enum CampaignType {
  /** 교차할인 */
  CROSS_DISCOUNT = 'cross',
  /** 고객등급 */
  CUSTOMER_GRADE = 'cust_grade',
  /** 세트할인 */
  BUNDLE_DISCOUNT = 'bundle',
  /**
   * 알 수 없음
   * - 1KMWINE에서 대응개발하지 않은 캠페인 형태
   */
  UNKNOWN = 'unknown'
}

/** 고객구분 */
export enum CustGbnFg {
  /** 고객(전체) */
  ALL = '0',

  /** 등급 */
  GRADE = '1',

  /** 고객(선택) */
  SELECT = '2'
}

/** 조건상품적용구분 */
export enum CondGoodAplyFg {
  /** 전체 */
  ALL = '0',

  /** 특정상품 */
  SPECIFIC = '1'
}

/**
 * 조건값구분
 * - 0: 금액
 * - 1: 수량
 *
 * @enum {string}
 */
export enum CondValueFg {
  /** 금액 */
  AMOUNT = '0',

  /** 수량 */
  QUANTITY = '1'
}

/**
 * 조건할인구분
 * - 0: 영수증
 * - 1: 상품
 * - 2: 쿠폰
 *
 * @enum {string}
 */
export enum ConfDcFg {
  /** 영수증 */
  RECEIPT = '0',

  /** 상품 */
  PRODUCT = '1',

  /** 쿠폰 */
  COUPON = '2'
}

/**
 * 혜택적용기준
 * - 0: 할인율
 * - 1: 금액할인
 * - 2: 지정금액
 * - 3: 증정품
 * - 4: 할인율(합)
 * - 5: 지정금액(합)
 * - 6: 포인트차감
 *
 * @enum {string}
 */
export enum BenfAplyFg {
  /** 할인율 */
  DISCOUNT_RATE = '0',

  /** 금액할인 */
  AMOUNT_DISCOUNT = '1',

  /** 지정금액 */
  FIXED_AMOUNT = '2',

  /** 증정품 */
  GIVE_ITEM = '3',

  /** 할인율(합) */
  TOTAL_DISCOUNT_RATE = '4',

  /** 지정금액(합) */
  TOTAL_FIXED_AMOUNT = '5',

  /** 포인트차감 */
  POINT_DEDUCTION = '6'
}

/**
 * 조건기준구분
 * - 0: OR
 * - 1: AND
 *
 * @enum {string}
 */
export enum ConBasFg {
  /** OR */
  OR = '0',

  /** AND */
  AND = '1'
}

/**
 * 중복적용구분
 * - 0: 허용
 * - 1: 불가
 */
export enum DupAplyFg {
  /** 허용 */
  ALLOW = '0',

  /** 불가 */
  DENY = '1'
}

/**
 * 처리상태
 * - 0: 등록
 * - 1: 확정
 */
export enum ProcStatFg {
  /** 등록 */
  REGISTERED = '0',
  /** 확정 */
  CONFIRMED = '1'
}

export interface RawCampMst<DataSource extends 'oracle' | 'mysql' = 'mysql'> {
  CAMP_CD: string;
  CAMP_NM: string;
  CHAIN_MS_NO: string;
  CAMP_STT_DATE: string;
  CAMP_END_DATE: string;
  CUST_GBN_FG: CustGbnFg;
  CONF_DC_FG: ConfDcFg;
  COND_GOOD_APLY_FG: CondGoodAplyFg;
  COND_VALUE_FG: CondValueFg;
  CPN_CD: string | null;
  BENF_APLY_FG: BenfAplyFg;
  CON_BAS_FG: ConBasFg;
  DUP_APLY_FG: DupAplyFg;
  PRICE_IF_TRAN_YN: 'Y' | 'N';
  APLY_RANK_NO: number;
  PROC_STAT_FG: ProcStatFg;
  CREATE_DTIME: string;
  CREATE_ID: string;
  LAST_DTIME: string;
  LAST_ID: string;
  IF_SEND_YN: 'Y' | 'N';
  IF_SEND_MSG: string | null;
  IF_SEND_DTIME: string | null;
  SMS_TRAN_YN: 'Y' | 'N';
  CPN_BBS_TEXT: string | null;

  /**
   * 동기화 일시 (YYYYMMDDHHmmss)
   *
   * raw data를 Oracle에서 가져왔을 때는 없고,
   * MySQL에서 가져왔을 때만 있는 필드
   */
  SYNC_DTIME: DataSource extends 'mysql' ? string : never;
}

/**
 * 캠페인매장마스터
 *
 * @property {string} CAMP_CD - 캠페인코드(년월(4)+법인(4)+순번(4))
 * @property {string} MS_NO - 매장코드
 * @property {'Y' | 'N'} END_YN - 종료여부(N:진행, Y:종료)
 * @property {string} END_ID - 종료담당자ID (영업정보시스템ID)
 * @property {string} END_DTIME - 종료일시 (YYYYMMDDHHmmss)
 * @property {string} CREATE_DTIME - 등록일시 (YYYYMMDDHHmmss)
 * @property {string} CREATE_ID - 등록자 (영업정보시스템ID)
 * @property {string} LAST_DTIME - 수정일시 (YYYYMMDDHHmmss)
 * @property {string} LAST_ID - 수정자 (영업정보시스템ID)
 */
export interface RawCampShopMst {
  /** 캠페인코드(년월(4)+법인(4)+순번(4)) */
  CAMP_CD: string;
  /** 매장코드 */
  MS_NO: string;
  END_YN: 'Y' | 'N';
  END_ID: string;
  END_DTIME: string;
  CREATE_DTIME: string;
  CREATE_ID: string;
  LAST_DTIME: string;
  LAST_ID: string;
}

/** 캠페인 구간설정 */
export type RawCampRangeMst = {
  /** 캠페인 코드 */
  CAMP_CD: string;

  /** 구간코드 */
  RANGE_CD: string;

  /** 구간조건값 */
  RANGE_COND_VAL: number;

  /** 구간할인값 */
  RANGE_DC_VAL: number;

  /** 종료여부 */
  END_YN: 'Y' | 'N';

  /** 종료담당자ID (영업정보시스템ID) */
  END_ID: string;

  /** 종료일시 (YYYYMMDDHHmmss) */
  END_DTIME: string;

  /** 등록일시 (YYYYMMDDHHmmss) */
  CREATE_DTIME: string;

  /** 등록자ID (영업정보시스템ID) */
  CREATE_ID: string;

  /** 수정일시 (YYYYMMDDHHmmss) */
  LAST_DTIME: string;

  /** 수정자ID (영업정보시스템ID) */
  LAST_ID: string;

  /** 동기화일시 (YYYYMMDDHHmmss) */
  SYNC_DTIME: string;
};

/**
 * Client용 캠페인 구간설정
 * @deprecated
 */
// export type TCampRange = {
//   /** 구간코드 */
//   code: RawCampRangeMst['RANGE_CD'];
//   /** 구간조건값 */
//   conditionVal: RawCampRangeMst['RANGE_COND_VAL'];
//   /** 구간할인값 */
//   discountVal: RawCampRangeMst['RANGE_DC_VAL'];
// };

export type TCampItem = {
  /** 상품의 pdata 아이디 */
  pdata_id: string;
  /** 상품의 ERP 상품코드 */
  item_cd: string;
  /** 상품 분류 */
  category: 'wine' | 'whiskey' | 'nihonshu' | string; // todo type
  /** 상품 타입 */
  type: 'RED' | 'WHITE' | 'ROSE' | 'SPARKLING' | 'ETC' | string; // todo type
  /** 상품명 */
  name: {
    /** 한글 상품명 */
    ko: string;
    /** 영문 상품명 */
    en: string;
  };
  /** 빈티지 */
  vintage: 'NV' | string;
  /** 용량 */
  capacity: number;
  /** 국가 */
  country: { id: string; ko: string; en: string };
  /** 품종 */
  variety: { id: string; ko: string; en: string };
  /** 좋아요 수 */
  wishes: number;
  /** 작성된 리뷰 수 */
  reviews: number;
  /** 사용자 평점 */
  star: number;
  /** 판매상품 최저가 */
  min_price_original?: number;
};

/**
 * 캠페인 매장
 */
export interface TCampVendor {
  /** vendor ID */
  _id: string;
  /** 나라포스 매장코드 */
  ms_no: string;
  /** 매장정보 */
  biz: {
    /** 매장명 */
    name: string;
    /** 매장주소 1 */
    address1: string;
    /** 매장주소 2 */
    address2: string;
    /** 매장위치 좌표 */
    location: {
      /** 경도 (Longitude) */
      x: number;
      /** 위도 (Latitude) */
      y: number;
    };
  };
  /** 입점샵 대표 이미지 */
  vendor_img?: [TVendorImage];
}

/**
 * 교차할인 캠페인 매장
 */
export interface TCrossDiscCampVendor extends TCampVendor {
  /** 판매 중인 상품들의 재고 합 */
  tot_qty: number;
}

/** 나라셀라 캠페인 */
export interface TNaraCampaign {
  /** 캠페인 타입 */
  type?: CampaignType;
  /** 캠페인코드 */
  camp_cd: string;
  /** 캠페인명칭 */
  camp_nm: string;
  /** 시작일자 */
  camp_stt_date: string;
  /** 종료일자 */
  camp_end_date: string;

  /** 고객구분 */
  // cust_gbn_fg: CustGbnFg;
  /** 조건할인구분 */
  // conf_dc_fg: ConfDcFg;
  /** 혜택적용기준 */
  benf_aply_fg: BenfAplyFg;
  /** 중복적용구분(0:허용, 1:불가) */
  dup_aply_fg: '0' | '1';
  /** 적용순위번호 */
  aply_rank_no: number;
}

/**
 * 교차할인 캠페인
 */
export interface TNaraCrossDiscountCampaign extends TNaraCampaign {
  /** 구간설정 목록 */
  // ranges: TCampRange[];
  ranges: { range_cd: string; range_cond_val: number; range_dc_val: number }[];
  /** 캠페인상품정보 */
  items: TCampItem[];

  /**
   * 캠페인 매장정보
   * -> 나라POS 매장과 매치되는 1KMWINE의 vendor 정보
   */
  vendors: TCrossDiscCampVendor[];
}

/** 상품 할인 정보 */
export type ProdDiscountInfo = {
  product: any; // todo
  type: 'percentage' | 'fixed';
  discount: number;
  discountLabel: string;
  before: number;
  after: number;
};
