import React, { FC } from 'react';

import wineDefaultImg from 'assets/images/default_wine.webp';

// material-ui
import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

export interface PdataImageAttributes {
  id: string;
  onSale?: boolean;
  children?: React.ReactNode;
  thumb?: boolean;
  width?: string | number;
  height?: string | number;
  alt?: string;
}

export type PdataImageProps = PdataImageAttributes & BoxProps;

const PdataImage: FC<PdataImageProps> = ({
  id,
  onSale = false,
  thumb = false,
  children,
  width = '100%',
  height = '100%',
  alt = '상품 이미지',
  ...rest
}) => {
  const imageUrl = React.useMemo(() => {
    return `/api/pdata/${id}/img${thumb ? '?thumb=1' : ''}`;
  }, [id, thumb]);

  // render
  return (
    <Box className="pdata-image-wrapper" width={width} height={height} borderRadius="10px" bgcolor="#F4F0F8" {...rest}>
      <MemoizedImgBox imgUrl={imageUrl} alt={alt} />
      {onSale ? <SaleChip /> : null}
      {children ?? null}
    </Box>
  );
};

export default PdataImage;

const MemoizedImgBox = React.memo(
  ({ imgUrl, alt }: { imgUrl: string; alt: string }) => (
    <Box
      component="img"
      className="pdata-img"
      src={imgUrl}
      alt={alt}
      onError={(e) => {
        (e.target as HTMLImageElement).src = wineDefaultImg.src;
      }}
    />
  ),
  (prevProps, nextProps) => prevProps.imgUrl === nextProps.imgUrl
);

const SaleChip = React.memo(() => (
  <Box
    sx={(theme) => ({
      position: 'absolute',
      top: '4px',
      left: '4px',
      py: '4px',
      px: '5px',
      borderRadius: '11px',
      display: 'flex',
      alignItems: 'center',
      bgcolor: theme.palette.primary.main
    })}
  >
    <Box
      component="img"
      src={'/assets/images/ico_shop_list_discount.png'}
      sx={{ width: '12px !important', height: '13px !important', mr: '2px' }}
    />
    <Typography sx={{ color: '#fff', fontSize: '10px', fontWeight: 600, lineHeight: 1 }}>할인</Typography>
  </Box>
));
SaleChip.displayName = 'WoSaleChip';
